'use strict';

// ==================
// --- SELECTIONS ---
// ==================

// ELEMENTS:
const header = document.querySelector('.header');
const navBar = document.querySelector('.nav-bar');
const navLogo = document.querySelector('.nav__logo');
const navLink = document.querySelectorAll('.nav__link');
const navLinks = document.querySelector('.navLinks');
const linksContainer = document.querySelector('.links__container');
const respNavIcon = document.querySelector('.nav-resp__icon');
const btnScrollTo = document.querySelector('.btn--scroll-to');
const btnStatuses = document.querySelector('.btn--statuses');
const btnNews = document.querySelector('.btn--news');
const btnAdmission = document.querySelector('.btn--admission');
const mapContainer = document.querySelector('.map-container');
const navRespCheckbox = document.querySelector('.nav-resp__checkbox');

// SECTIONS:
const serve = document.querySelector('#serve');
const locations = document.querySelector('#locations');

// =========================
// --- STICKY NAVIGATION ---
// =========================

// In order to implement the sticky navigation:
//  1. Our target should be the header
//  2. Root should be the viewport (null)
//  3. The threshold should be 0 (we want to show the nav bar whenever the header is not intersecting, and hide when it is)
//  4. IMPROVEMENT: We can change the threshold to show the nav when a part the size of the nav bar height of the header, or less, is visible. This way, we will be able to see the nav bar and the entire section 1. For this, we will use the 'rootMargin' option.

// Callback function:
const stickyNav = function (entries) {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      navBar.classList.add('sticky');
      navLogo.style.display = 'block';
      navLink.forEach((link) => link.classList.add('sticky'));
      respNavIcon.classList.add('sticky');
    } else {
      navBar.classList.remove('sticky');
      navLogo.style.display = 'none';
      // navBar.style.borderBottom = '0';
      navLink.forEach((link) => link.classList.remove('sticky'));
      respNavIcon.classList.remove('sticky');
    }
  });
};

// Navbar height:
const navHeight = navBar.getBoundingClientRect().height;

// Observer:
const stickyObserver = new IntersectionObserver(stickyNav, {
  root: null,
  threshold: 0,
  rootMargin: `-${navHeight}px`,
});
stickyObserver.observe(header);

// =================================
// --- SMOOTH SCROLLING (BUTTON) ---
// =================================

// Add an event listener to the button (btnScrollTo):
btnScrollTo.addEventListener('click', function (e) {
  // Scroll to the target element using 'scrollIntoView'. It will only work on modern browsers, but we won't need to get any coordinates and calculate the absolute position of the target element:
  serve.scrollIntoView({ behavior: 'smooth' });
});

// ==========================
// --- PAGE NAVIGATIONS ---
// ==========================

// NAVIGATE TO HOME:
navLogo.addEventListener('click', function (e) {
  window.location.href = '/index.html';
});

// VISIT AND DOWNLOAD STATUSES:
btnStatuses.addEventListener('click', function (e) {
  //   window.location.href = '/resources/estatutos.pdf';
  window.open('/resources/estatutos.pdf', '_blank');
});

// VISIT NEWS PAGE:
btnNews.addEventListener('click', function (e) {
  //   window.location.href = '/resources/estatutos.pdf';
  window.open('https://noticias.serve.org.es/', '_blank');
});

// VISIT AND DOWNLOAD ADMISSION FORM:
btnAdmission.addEventListener('click', function (e) {
  //   window.location.href = '/resources/estatutos.pdf';
  // window.open('../resources/solicitud_de_admision.pdf', '_blank');
  window.open('https://forms.gle/GNa9SGRizTGWvSvYA', '_blank');
});

// =====================
// RESPONSIVE NAVIGATION
// =====================

const showResponsiveMenu = function () {
  linksContainer.style.display = 'block';
  linksContainer.classList.add('responsive');
  navLink.forEach((link) => link.classList.add('responsive'));
};

const hideResponsiveMenu = function () {
  linksContainer.style.display = 'none';
  linksContainer.classList.remove('responsive');
  navLink.forEach((link) => link.classList.remove('responsive'));
};

navRespCheckbox.addEventListener('change', function () {
  if (this.checked) {
    showResponsiveMenu();
  } else {
    hideResponsiveMenu();
  }
});

// Close the responsive menu when clicking on a nav item:
navLink.forEach((link) =>
  link.addEventListener('click', function () {
    if (linksContainer.classList.contains('responsive')) {
      navRespCheckbox.checked = false;
      hideResponsiveMenu();
    }
  })
);

// =================
// LOCATIONS SECTION
// =================

let associates;

const initMap = async function () {
  try {
    // Get associates info from JSON file, and store it in to 'associates' array:
    const resLocations = await fetch('./data/associates.json');
    associates = await resLocations.json();
    // console.log(associates);

    // initialize the map, centered in Madrid:
    const map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 40.4202, lng: -3.6887 },
      zoom: 6,
    });

    // Add the markers and infoWindows to the map, for every associate:
    associates.forEach((associate) => {
      const infoWindow = new google.maps.InfoWindow({
        content: `<p style="font-size:14px; margin-bottom:3px;"><strong>${associate.name}</strong> <a href="${associate.web}" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></a></i></p><p style="margin-bottom:2px;">${associate.address} - ${associate.city}</p><p><a href="https://www.google.es/maps/search/?api=1&query=${associate.coords[0]},${associate.coords[1]}" target="_blank">Cómo llegar</a></p>`,
      });

      const marker = new google.maps.Marker({
        position: { lat: associate.coords[0], lng: associate.coords[1] },
        map,
        title: associate.name,
        optimized: false,
      });

      // Add a click listener for each marker, and set up the info window:
      marker.addListener('click', () => {
        infoWindow.close();
        infoWindow.open(marker.getMap(), marker);
      });
    });
  } catch (err) {
    console.error(err);
  }
};

// Call the 'initMap' function, to display the map and the markers:
window.initMap = initMap;
